import styled from "styled-components";

export const AppContainer = styled.div`
  background: var(--background-color);
  width: 100%;
  display: flex;
  // align-items: center;
  // align-content: center;
  // justify-content: center;
  flex-direction: row;
`;

export const Container = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const NotFoundContainer = styled.div`
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
  svg {
    width: 150px;
  }
  h5 {
    text-align: center;
    padding: 10px 0;
  }
`;
