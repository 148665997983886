/* eslint-disable no-unused-vars */
import { isEmpty } from "lodash";
// Copied from node-functions, don't edit here, only there

// When inputting or converting roles, use lookup aliases to find the canonical role name that is currently in use.
const ROLE_ALIAS = {
  superadmin: "superAdmin",
  superAdmin: "superAdmin",
  admin: "admins",
  admins: "admins",
  member: "all",
  user: "all",
};

const ROLES = {
  // TODO handle in schema and translation instead?
  all: {
    roleName: "Member",
    teamName: "All",
    roleId: "all",
    rank: 1,
  },
  manager: {
    roleName: "Manager",
    teamName: "Managers",
    roleId: "manager",
    rank: 2,
  },
  admins: {
    roleName: "Admin",
    teamName: "Admins",
    roleId: "admins",
    rank: 3,
  },
  // TODO how to represent superadmin role. It's an admin that matches any orgs, plus additional rights?
  // Could be any_superadmin, where any matches any org id.
  superAdmin: {
    roleName: "Super Admin",
    teamName: "Super Admins",
    roleId: "superAdmin",
    rank: 4,
  },
  // TODO We could use a special role to denote a user that has been invited to play a single scenario,
  // so that we don't have to figure out which IDs are scenarios and which are orgs
  // player: {
  //   roleName: "Player",
  //   teamName: "Players",
  // },
};

/*
Example claims objects:
a = { has: { '123_user': true, '456_admin': true } };
b = { has: { '123_user': true, '456_admin': true, 'superAdmin': true } };
c = { has: {}}
d = { has: { '123_user': true, '456_admin': true, '789_superAdmin': true } };
*/

/**
 * Checks if a claims object provides authorization to a resource based on its sharedWith object.
 * @param {object} claims
 * @param {object} sharedWith
 * @param {string[]} extraClaims any extra claims to consider for this call
 * @returns {boolean|null} true if write access, false if read access, null if no access
 */
const checkSharedWith = (claims, sharedWith, extraClaims = []) => {
  if (hasClaimFor(claims, "superAdmin", null)) return true;
  if (isEmpty(sharedWith)) return null;
  let allClaims = [...(claims?.memberOf || []), ...Object.keys(claims?.has || {}), ...extraClaims];
  let access = allClaims
    // lookup each user claim in sharedWidth, getting true for write, false for read or null/undefined no access
    .map(claim => sharedWith[claim])
    // Only allow boolean values
    .filter(sharedClaim => sharedClaim === true || sharedClaim === false)
    // returns true if any sharedClaims were true, else false if any were false, else null if no access
    .reduce((acc, claim) => acc || claim, null);
  return access;
};

const checkSuperAdmin = claims => hasClaimFor(claims, "superAdmin", null);

const checkAdmin = (claims, orgId) => hasClaimForRoles(claims, ["superAdmin", "admins"], orgId);

const checkManager = (claims, orgId) => hasClaimForRoles(claims, ["superAdmin", "admins", "manager"], orgId);

/**
 * Checks if a claim object includes a claim to any of the roles (in order) against an id
 * NOTE: To ensure superAdmin always gets access, make sure to add it to the list of roles.
 * @param {object} claims parsed auth token containing `has` or `memberOf` properties.
 * @param {string[]} roles array of identifiers for a supported role
 * @param {string} id identifier of an organization (or in some cases scenario)
 * @returns {boolean} returns true if has claim, false if not
 */
const hasClaimForRoles = (claims, roles, id) => {
  if (!claims || !roles?.length) return false;
  return roles.some(role => hasClaimFor(claims, role, id));
};

/**
 * Checks if a claims object includes a claim to a specific id and/or role.
 * To check if a user has *any* id with specific role, set id to null.
 * To check if a user has *any* (or no) role with specific id, set role to null.
 * @param {object} claims a parsed auth token containing `has` or `memberOf` properties.
 * @param {string} role identifier for a supported role
 * @param {string} id identifier of an organization (or in some cases scenario)
 * @returns {boolean} returns true if has claim, false if not
 */
const hasClaimFor = (claims, role, id) => {
  if (!claims?.has) return false;
  let foundClaim;
  if (role) {
    // TODO fix case of superAdmin
    if (role === "superAdmin" && claims.has[role]) return true;
    if (id) {
      foundClaim = claims.has?.[`${id}_${role}`];
    } else {
      // find instead of some so that we return null when not found instead of false
      foundClaim = Object.keys(claims.has || {}).find(claim => claim.endsWith(`_${role}`));
    }
  } else if (id) {
    foundClaim = claims.has?.[id];
  }
  if (foundClaim) {
    return true;
  } else {
    return false;
  }
};

/**
 * Checks that an candidate user has any of given roles on any id from another users claims.
 * E.g. checks if a candidate is an admin for any organization that another user is a user of.
 * in the authuser object.
 * NOTE: To ensure superAdmin always gets access, make sure to add it to the list of roles.
 * @param {object} candidateClaims
 * @param {object} userClaims
 * @param {string[]} roles to check for
 * @returns {boolean}
 */
const hasRoleOverUser = (candidateClaims, userClaims, roles) => {
  if (!userClaims || !candidateClaims) return false;
  let userOrgs = getIdsWithRoles(userClaims, null);
  return userOrgs.some(orgId => hasClaimForRoles(candidateClaims, roles, orgId)) || checkSuperAdmin(candidateClaims);
};

const checkAdminOver = (candidateClaims, userClaims) =>
  hasRoleOverUser(candidateClaims, userClaims, ["superAdmin", "admins"]);

/*
Example claims objects:
a = { has: { '123_user': true, '456_admin': true } };
b = { has: { '123_user': true, '456_admin': true, 'superAdmin': true } };
c = { has: {}}
d = { has: { '123_user': true, '456_admin': true, '789_superAdmin': true } };
*/

/**
 * Parses a claimKey (e.g. "orgid_role") into an object representing what we know about the claim.
 * Unknown roles will receive a null role, roleName and teamName.
 * @param {string} claimKey
 * @returns {object}
 */
const parseClaim = claimKey => {
  const parts = claimKey.split("_");
  let id = parts[0];
  let role = parts[1];
  if (id === "superAdmin") {
    role = "superAdmin";
    id = null;
  }
  let claimObject = {
    claim: claimKey,
    id,
    roleId: null,
    roleName: null,
    teamName: null,
  };
  let roleObject = ROLES[role] || {};
  return { ...claimObject, ...roleObject };
};

/**
 * Parses a claim object for all id and roles (returns only claims with a role).
 * @param {object} claims a claim object
 * @returns {object[]}
 */
const getUserRoles = claims => {
  return Object.keys(claims?.has || [])
    .map(parseClaim)
    .filter(display => display.roleId)
    .sort((a, b) => (a.id || "").localeCompare(b.id) || b.rank - a.rank);
};

/**
 * Returns a unique and alphabetically sorted list of ids that the user has the given roles for in her claims.
 * @param {object} claims a claim object
 * @param {string[]} roles an array of roles or null if any role or no role is accepted
 * @returns {string[]} an array of unique ids
 */
const getIdsWithRoles = (claims, roles) => {
  const orgIds = Object.keys(claims?.has || [])
    .filter(key => (roles?.length ? roles.some(role => key.endsWith(`_${role}`)) : true))
    .map(key => key.split("_")[0])
    .sort();
  return Array.from(new Set(orgIds));
};

/**
 * Returns a string showing the role name for a claim object and a given ID.
 * @param {object} claims claims object
 * @param {string} selectedId selected ID (e.g. organization)
 * @returns {string} a role name or Limited if not found
 */
const getUserType = (claims, selectedId) => {
  if (!claims || !selectedId) return "Limited";

  // Check for superAdmin first as it has no id
  const claimsWithRole = getUserRoles(claims);
  const superAdminRole = claimsWithRole.find(claim => claim.roleId === ROLES.superAdmin.roleId);
  if (superAdminRole) return superAdminRole.roleName;

  // Then check org-specific roles
  let parsedClaims = claimsWithRole.filter(claim => claim.id === selectedId);
  if (isEmpty(parsedClaims)) return "Limited";
  return parsedClaims[0].roleName;
};

export {
  checkSharedWith,
  checkSuperAdmin,
  checkAdmin,
  checkManager,
  getUserType,
  hasClaimFor,
  getUserRoles,
  getIdsWithRoles,
};
