import styled from "styled-components";

export const NavWrapper = styled.div`
  flex-shrink: 0;
`;

export const DesktopNavWrapper = styled.div`
  background: white;
  width: 88px;
  position: relative;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const StyledNavContainer = styled.nav`
  position: fixed;
  background: var(--background-color-white);
  height: 100vh;
  top: 0;
  left: 0;
  width: 160px;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.1);
  z-index: 10;
  overflow-y: hidden;
  padding-bottom: 20px;

  img {
    margin-top: 14px;
    width: 50px;
  }

  @media (min-width: 769px) {
    width: 88px;
    padding-top: 0;
    padding-bottom: 0;
  }
`;

export const StyledNavList = styled.ul`
  height: 100%;
  width: 100%;
  padding-top: 20px;

  li:first-child {
    margin: 0 0 15px;
  }

  @media (min-width: 450px) {
    padding-top: 50px;
  }
`;

export const StyledNavListItem = styled.li`
  width: 100%;
  text-align: center;
  transition: 0.4s;
  padding: 10px 0;
  margin: 15px 0;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;

  @media (min-width: 769px) {
    border-left: ${props => (props.selected ? "4px solid var(--key-color-2)" : "4px solid transparent")};
    margin: 20px 0;
  }

  button {
    background-color: transparent;
    border: 0;
    color: ${props => (props.selected ? "var(--key-color-2)" : "var(--grey-3)")};

    &:focus-visible {
      outline: 1px solid black;
    }

    svg {
      transition: 0.4s;
      width: 30px;
      color: inherit;
    }

    @media (min-width: 769px) {
      p {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        white-space: nowrap;
        border-width: 0;
      }
    }
  }

  &:hover {
    svg {
      transition: 0.4s;
      color: var(--key-color-2);
    }
  }
`;

export const UserInfoContainer = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  font-weight: bold;
  padding: 10px;

  h5 {
    span {
      font-size: 12px;
      margin-top: 5px;
      color: var(--key-color-2);
    }
  }

  h1 {
    pointer-events: none;
    width: 42px;
    height: 42px;
    vertical-align: middle;
    line-height: 1.1;
    border-radius: 50%;
    background: var(--key-color-2-gradient);
    font-size: 20px;
    font-weight: bold;
    color: white;
  }

  svg {
    display: none;
    color: var(--key-color-2);
    transition: 0.4s;
  }

  &:hover {
    svg {
      display: block;
      transition: 0.4s;
      color: var(--key-color-2);
    }

    h1 {
      visibility: hidden;
    }
  }
`;

// ****************** MOBILE **********************

export const MobileNavWrapper = styled.div`
  display: none;
  padding: 10px 15px;
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 10;
  background: transparent;
  width: min-content;

  img {
    width: 70px;
  }

  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
  }
`;

export const HamburgerMenuButton = styled.div`
  background: var(--key-color-2);
  border: none;
  outline: none;
  color: white;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 20px;
    height: 20px;
    color: white;
  }
`;

export const SliderBlanket = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2) none repeat scroll 0% 0%;
  animation: 0.2s ease-out 0s 1 normal none running eMLfYp;
  z-index: 100;
`;

export const SliderContainer = styled.div`
  height: 100dvh;
  position: fixed;
  top: 0px;
  transition: transform 0.2s ease-out 0s;
  z-index: 205;
  right: 0px;
  transform: translateX(${props => (props.open ? "0" : "304px")});
  width: 160px;
  display: flex;
  flex-direction: row;
`;
